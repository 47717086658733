<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 mb-4">
        <h3 class="m-0">{{ SHARED.Email.Title }}</h3>
      </div>
    </div>

    <div class="row mb-4">
      <div class="col-5">
        <recess-input
          v-model="defaultEmailAddress.emailAddress"
          :label-text="SHARED.Email.DefaultEmail.Label"
          class="qa-provider-default-email"
          :error-message="providerDefaultEmailError()"
          @blur="$v.defaultEmailAddress.emailAddress.$touch()"
          :disabled="isAccountManager"
        />
      </div>
      <div class="col-5">
          <recess-toggle-input
              v-model="defaultEmailAddress.excludeIncompanyOrders"
              name="excludeIncompanyOrders"
              class="qa-exclude-incompany ml-2 s-simulate-input-holder"
              size="small"
              :label-text="SHARED.Email.ExcludeIncompanyOrders.Label"
              :disabled="isAccountManager"
            /> 
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <h4 class="mb-1">{{ SHARED.Email.OtherSpecificEmail.Label }}</h4>
        <p class="mb-2">
          {{ SHARED.Email.OtherSpecificEmail.Description }}
        </p>
      </div>
    </div>

    <div v-if="hasEmailAddresses" class="row">
      <div
        v-for="(item, index) in emailAddresses"
        :key="`segment-${index}`"
        class="col-12 d-flex flex-wrap align-items-start mb-4"
      >
        <div class="row u-flex-grow-1 qa-email-addresses-item my-0">
          <div class="col-6">
            <recess-multi-select
              :selected-options="item.accountReference"
              :options="accountsOptions"
              :search-input="true"
              :single-select="true"
              :label-text="SHARED.Email.EmailAccountSelect.Label"
              :placeholder="SHARED.Email.EmailAccountSelect.Placeholder"
              :error-message="emailAddressError(index)"
              :class="`w-100 qa-email-address-${index}`"
              @input="(newValue) => onAccountChange({emailAddressIndex: index, accountReference: newValue})"
              @blur="$v.emailAddresses.$each[index].accountReference.$touch()"
            />
          </div>

          <div class="col-6 d-flex justify-content-start">
            <recess-input
              v-model="item.emailAddress"
              :label-text="SHARED.Email.EmailAddress.Label"
              class="w-100 qa-provider-email"
              :error-message="providerEmailError(index)"
              @blur="$v.emailAddresses.$each[index].emailAddress.$touch()"
              :disabled="isAccountManager"
            />

            <div
              class="d-flex align-items-center s-delete-icon__input-align ml-3"
              v-if="emailAddresses !== null && emailAddresses.length > 0 && !isAccountManager"
            >
              <span
                :class="`d-flex c-icon c-icon__bin qa-remove-email-address-button-${index}`"
                @click="removeAccountEmailAddressItem(item, index)"
              ></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- show placeholder if there is no saved legal company -->
    <div v-else class="row">
      <div class="col-12">
        <h4>{{ SHARED.Email.NoEmailAddresses.Label }}</h4>
      </div>
    </div>
    <div class="row" v-if="!isAccountManager">
      <div class="col-12 d-flex justify-content-end">
        <recess-button
          :title="BUTTON_TEXT.add"
          icon="add"
          variant="variant4"
          class="qa-add-email-address-item-button"
          @click.native.prevent="addAccountEmailAddressItem()"
        />
      </div>
    </div>
    <div class="row" v-if="!isAccountManager">
      <div class="col-12 d-flex justify-content-end">
        <recess-button
          :title="BUTTON_TEXT.save"
          variant="secondary"
          class="qa-edit-email-addresses-button"
          @click.native.prevent="saveFees"
        />
      </div>
    </div>
  </div>
</template>


<script>
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import { SHARED } from "../../constants/EdumsSharedConstants.json";
import { API_CALL_URL_PATHS, BUTTON_TEXT } from "../../constants/constantsGeneral.json";
import {
  getItems,
  getItemsFromSearchIndex,
  postItem,
  patchItem,
  deleteItem,
} from "../../api/SharedClient";

import { setDataToBePatchedWhenValueIsUpdated } from "@/utils/vuexFunctionHelper";
import { isRequiredErrorMessage, hasEmailError, isUniqueErrorMessage, defaultErrorMessage, emailSuccessAction  } from "../../constants/validationMessageHelper.js";

export default {
  name: "ProviderEmails",
  mixins: [validationMixin],
  props: {
    isAccountManager: {
      type: Boolean,
      default() {
        return false
      }
    },
    providerId: {
      type: String,
      default() {
        return "";
      },
    },
  },
  validations: {
    defaultEmailAddress: {
      emailAddress: { required, email },
    },
    emailAddresses: {
      $each: {
        accountReference: {
          required,
          isUnique(value, address) {
            const emailAddressesForAccount = this.emailAddresses.filter(
              (emailAddress) => {
                return (
                  emailAddress.accountReference === address.accountReference
                );
              }
            );
            return emailAddressesForAccount.length < 2;
          },
        },
        emailAddress: { required, email },
      },
    },
  },
  data() {
    return {
      BUTTON_TEXT,
      SHARED,
      assortedProducts: [],
      accounts: [],
      defaultEmailAddress: {
        providerId: this.providerId,
        accountReference: null,
        emailAddress: null,
        excludeIncompanyOrders: false
      },
      emailAddresses: [],
      originalEmailAddresses: [],
    };
  },
  computed: {
    accountsOptions() {
      const filteredAccounts = this.accounts.filter((account) => {
        return this.assortedProducts.some((assortedProduct) => {
          return assortedProduct.value === account.id;
        });
      });

      return filteredAccounts.map((account) => {
        const accountOption = {};
        accountOption.value = `account:${account.id}`;
        accountOption.displayText = account.name;
        return accountOption;
      });
    },
    hasEmailAddresses() {
      return this.emailAddresses && this.emailAddresses.length > 0;
    },
  },
  watch: {
    providerId: function providerLoaded() {
      if (this.providerId) {
        this.getAccountOptions();
        this.getProviderEmails();
      }
    },
  },
  mounted() {
    this.$nextTick(async () => {
      if (this.providerId) {
        this.getAccountOptions();
        this.getProviderEmails();
      }
    });
  },
  methods: {
    providerDefaultEmailError() {
      if (!this.$v.defaultEmailAddress.emailAddress.$error) return null;

      return (
        isRequiredErrorMessage('emailAddress', 'E-mailadres', this.$v.defaultEmailAddress.emailAddress) || 
        hasEmailError(this.$v.defaultEmailAddress.emailAddress) 
      )
    },
    providerEmailError(index) {
      if (!this.$v.emailAddresses.$each[index].emailAddress.$error) return null;

      return (
        isRequiredErrorMessage('emailAddress', 'E-mailadres', this.$v.emailAddresses.$each[index].emailAddress) ||
        hasEmailError(this.$v.emailAddresses.$each[index].emailAddress) 
      )
    },
    emailAddressError(index) {
      if (!this.$v.emailAddresses.$each[index].accountReference.$error) return null;

      return (
        isRequiredErrorMessage('accountReference', 'Klant', this.$v.emailAddresses.$each[index].accountReference) ||
        isUniqueErrorMessage('Klantnaam', this.$v.emailAddresses.$each[index].accountReference, 'isUnique')
      )
    },
    async getAccountOptions() {
      const promises = [];
      promises.push(this.getAccounts());
      promises.push(this.getAssortedProducts());
      await Promise.all(promises);
    },
    async getAssortedProducts() {
      try {
        const filterInput = `providerId eq '${this.providerId}'`;
        const filterAssortedProducts = {
          search: "",
          top: 0,
          skip: 0,
          facets: ["accountId, count:1000"],
          filter: filterInput,
        };

        const response = await getItemsFromSearchIndex(
          process.env.VUE_APP_ACCOUNT_API_URL,
          API_CALL_URL_PATHS.assortedProducts,
          API_CALL_URL_PATHS.search,
          filterAssortedProducts
        );

        if (!response) return;
        this.assortedProducts = response.facets.accountId;
      } catch (error) {
        console.error(
          "Something went wrong while retrieving assorted products",
          error
        );
      }
    },
    async getAccounts() {
      try {
        const response = await getItems(
          process.env.VUE_APP_ACCOUNT_API_URL,
          API_CALL_URL_PATHS.accounts,
          1,
          99999,
          null,
          "deleted eq false",
          false
        );

        if (!response) return;
        this.accounts = response.items;
      } catch (error) {
        console.error("Something went wrong while retrieving accounts", error);
      }
    },
    addAccountEmailAddressItem() {
      const emptyAccountAddressItem = {
        providerId: this.providerId,
        accountReference: "",
        emailAddress: "",
      };

      this.emailAddresses.push(emptyAccountAddressItem);
    },
    onAccountChange({ emailAddressIndex, accountReference }) {
      this.emailAddresses[emailAddressIndex].accountReference =
        accountReference;
    },
    async getProviderEmails() {
      try {
        const filterInput = `providerId eq ${this.providerId}`;
        const response = await getItems(
          process.env.VUE_APP_PROVIDER_API_URL,
          API_CALL_URL_PATHS.providerEmails,
          1,
          99999,
          null,
          filterInput,
          false
        );
        if (!response) return;
        this.setEmailAddressList(response.items);
      } catch (error) {
        console.error(
          "Something went wrong while retrieving provider emails",
          error
        );
      }
    },
    removeAccountEmailAddressItem(item, index) {
      this.deleteProviderEmailAddressById(item.id);
      this.emailAddresses.splice(index, 1);
    },
    setEmailAddressList(items) {
      this.emailAddresses = items.filter(
        (emailAddress) => emailAddress.accountReference
      );
      const defaultEmailAddresses = items.filter(
        (emailAddress) => !emailAddress.accountReference
      );
      if (defaultEmailAddresses.length > 0) {
        this.defaultEmailAddress = defaultEmailAddresses[0];
      }
      this.originalEmailAddresses = JSON.parse(JSON.stringify(items));
    },
    async deleteProviderEmailAddressById(providerEmailAddressById) {
      if (!providerEmailAddressById) {
        return;
      }

      await deleteItem(
        process.env.VUE_APP_PROVIDER_API_URL,
        `${API_CALL_URL_PATHS.providerEmails}/${providerEmailAddressById}`,
        false
      )
        .then(() => {
          const indexOfProviderEmailAddress =
            this.originalEmailAddresses.findIndex(
              (emailAddress) => emailAddress.id === providerEmailAddressById
            );
          this.originalEmailAddresses.splice(indexOfProviderEmailAddress, 1);

          const toastNotification = {
            type: "success",
            message: emailSuccessAction('bijgewerkt', true)
          };

          this.$store.dispatch(
            "toastNotificationModule/add",
            toastNotification,
            {
              root: true,
            }
          );
        })
        .catch((error) => {
          const toastNotification = {
            type: "error",
            message: defaultErrorMessage,
          };

          this.$store.dispatch(
            "toastNotificationModule/add",
            toastNotification,
            {
              root: true,
            }
          );

          console.error(
            "Something went wrong while deleting provider email account address item",
            error
          );
        });
    },
    async patchProviderEmailAddressById(
      providerEmailAddressById,
      objectToBePatched
    ) {
      await patchItem(
        process.env.VUE_APP_PROVIDER_API_URL,
        API_CALL_URL_PATHS.providerEmails,
        providerEmailAddressById,
        objectToBePatched,
        false
      )
        .then((response) => {
          if (!response) return;

          const toastNotification = {
            type: "success",
            message: emailSuccessAction('bijgewerkt', true)
          };

          this.$store.dispatch(
            "toastNotificationModule/add",
            toastNotification,
            {
              root: true,
            }
          );

          const indexOfProviderEmailAddress =
            this.originalEmailAddresses.findIndex(
              (emailAddress) => emailAddress.id === providerEmailAddressById
            );
          this.originalEmailAddresses[indexOfProviderEmailAddress] = response;
        })
        .catch((error) => {
          const toastNotification = {
            type: "error",
            message: defaultErrorMessage,
          };

          this.$store.dispatch(
            "toastNotificationModule/add",
            toastNotification,
            {
              root: true,
            }
          );

          console.error(
            "Something went wrong while patching provider account email address",
            error
          );
        });
    },
    async postProviderEmailAddress(newProviderEmailAddress) {
      await postItem(
        process.env.VUE_APP_PROVIDER_API_URL,
        API_CALL_URL_PATHS.providerEmails,
        newProviderEmailAddress,
        false
      )
        .then((response) => {
          if (!response) return;

          //TODO: Remove the if statement when toastNotificationModule will be added to CORE UI
          // https://evident-digital.atlassian.net/browse/ED-12685

          const toastNotification = {
            type: "success",
            message: emailSuccessAction('bijgewerkt', true)
          };

          this.$store.dispatch(
            "toastNotificationModule/add",
            toastNotification,
            {
              root: true,
            }
          );

          this.originalEmailAddresses.push(response);
        })
        .catch((error) => {
          const toastNotification = {
            type: "error",
            message: defaultErrorMessage,
          };

          this.$store.dispatch(
            "toastNotificationModule/add",
            toastNotification,
            {
              root: true,
            }
          );

          console.error(
            "Something went wrong while posting provider account email address",
            error
          );
        });
    },
    async saveFees() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let promises = [];

        const copyEmailAddresses = JSON.parse(
          JSON.stringify(this.emailAddresses)
        );
        copyEmailAddresses.push(this.defaultEmailAddress);

        const postEmailAddressesList = copyEmailAddresses.filter(
          (emailAddress) => !emailAddress.id
        );
        const patchtEmailAddressesList = copyEmailAddresses.filter(
          (emailAddress) => emailAddress.id
        );

        patchtEmailAddressesList.forEach((EmailAddress) => {
          const dataToBePatched = [];
          const keys = ["accountReference", "emailAddress", "excludeIncompanyOrders"];
          keys.forEach((key) => {
            setDataToBePatchedWhenValueIsUpdated(
              this.originalEmailAddresses,
              EmailAddress,
              key,
              dataToBePatched
            );
          });

          if (dataToBePatched.length > 0) {
            promises.push(
              this.patchProviderEmailAddressById(
                EmailAddress.id,
                dataToBePatched
              )
            );
          }
        });
        await Promise.all(promises);
        promises = [];
        postEmailAddressesList.forEach((emailAddress) => {
          promises.push(this.postProviderEmailAddress(emailAddress));
        });
        await Promise.all(promises);

        this.setEmailAddressList(this.originalEmailAddresses);
      }
    },
  },
};
</script>